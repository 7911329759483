import { PageProps } from 'gatsby';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslateData } from '../hooks';
import {
  About,
  KeyPartners,
  MassMediaBasis,
  PrinciplesOperation,
} from '../sections';

type IAboutNavigation = {
  metaTitle: string
  metaDescription: string
  metaKeywords: string
  url: string;
};

const AboutPage: FC<PageProps> = () => {
  const { metaTitle, metaDescription, metaKeywords } = useTranslateData<IAboutNavigation>(
    'navigationByKey.about'
  ) as IAboutNavigation;
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="icon" type="image/png" href="/favicon.png"></link>
        <link rel="shortcut icon" href="/favicon.ico" type="image/vnd.microsoft.icon" />
      </Helmet>
      <About />
      <PrinciplesOperation />
      <KeyPartners />
      <MassMediaBasis />
    </>
  );
};

export default AboutPage;
